import "./index.css";

import React from "react";
import ReactDOM from "react-dom";
import Automerge, { Connection } from "automerge";
import ReconnectingWebSocket from 'reconnecting-websocket';

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const LoadingIndicator = () => (
  <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
)

const ListItem = ({item, onToggleCompletion, onDelete, onToggleEditMode, onSave, editMode}) => {
  const inputRef = React.useRef(null);
  const liRef = React.useRef(null);

  const handleClickOutside = React.useCallback(e => {
    if (liRef.current.contains(e.target)) {
      return;
    }

    onToggleEditMode();
  }, [onToggleEditMode]);

  React.useEffect(() => {
    if (editMode) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [liRef, editMode, handleClickOutside]);

  return <li ref={liRef}>
    {editMode ? (
      <React.Fragment>
        <form onSubmit={() => onSave({...item, value: inputRef.current.value})}>
          <input defaultValue={item.value} type="text" ref={inputRef} />
          <input type="submit" value="✏️" />
        </form>
      </React.Fragment>
  ) : (
    <React.Fragment>
      <span className="listItem-checkbox" onClick={() => onToggleCompletion(item.id)}>
        {item.completed ? "✅" : "☑️"}
      </span>
      <span className="listItem-label" onClick={() => onToggleEditMode(item.id)}>{item.value}</span>
      <span className="listItem-delete" onClick={() => onDelete(item.id)}>❌</span>
    </React.Fragment>
  )}
    </li>
}

class App extends React.Component {
  constructor() {
    super();
    const urlQueryParameters = new URLSearchParams(window.location.search);
    const listId = urlQueryParameters.get("listId");
    this.state = { connected: false, listId, editItem: undefined };
    this.docSet = new Automerge.DocSet();
    this.input = React.createRef();
  }

  componentDidMount() {
    if (this.state.listId) {
      this.docSet.registerHandler((docId, doc) => {
        this.setState({});
        console.log(`[${docId}] ${JSON.stringify(doc)}`);
      });

      const ws = new ReconnectingWebSocket(process.env.REACT_APP_API_URL);

      ws.addEventListener("open", () => {
        console.log("connected");
        this.connection = new Connection(this.docSet, (msg) => {
          ws.send(JSON.stringify(msg));
        });
        this.connection.open();
        this.setState({connected: true})
      });

      ws.addEventListener("close", () => {
        console.log("disconnected");
        this.connection && this.connection.close();
      });

      ws.addEventListener("message", (event) => {
        console.log(`Received message => ${event.data}`);
        this.connection.receiveMsg(JSON.parse(event.data));
      });
    }
  }

  addItem = (event) => {
    event.preventDefault();
    const value = this.input.current.value;

    if (value) {
      const currentDoc = this.docSet.getDoc(this.state.listId);

      if (currentDoc) {
        const newDoc = Automerge.change(currentDoc, (doc) => {
          doc.items.unshift({
            id: uuidv4(),
            value: this.input.current.value,
            completed: false,
          });
        });
        this.docSet.setDoc(this.state.listId, newDoc);
      }

      this.input.current.value = "";
    }
  };

  toggleItemCompletion = (id) => {
    const currentDoc = this.docSet.getDoc(this.state.listId);

    if (currentDoc) {
      const newDoc = Automerge.change(currentDoc, (doc) => {
        const index = doc.items.findIndex((item) => item.id === id);

        doc.items[index].completed = !doc.items[index].completed;
      });
      this.docSet.setDoc(this.state.listId, newDoc);
    }
  };

  deleteItem = (id) => {
    const currentDoc = this.docSet.getDoc(this.state.listId);

    if (currentDoc) {
      const newDoc = Automerge.change(currentDoc, (doc) => {
        const index = doc.items.findIndex((item) => item.id === id);

        doc.items.deleteAt(index);
      });
      this.docSet.setDoc(this.state.listId, newDoc);
    }
  };

  deleteCompletedItems = () => {
    const currentDoc = this.docSet.getDoc(this.state.listId);

    if (currentDoc) {
      const newDoc = Automerge.change(currentDoc, (doc) => {
        for (let i = doc.items.length - 1; i >= 0; i -= 1) {
          if (doc.items[i].completed) {
            doc.items.deleteAt(i);
          }
        }
      });
      this.docSet.setDoc(this.state.listId, newDoc);
    }
  }

  toggleEditMode = (id) => this.setState({editItem: id});

  editItem = (updatedItem) => {
    const currentDoc = this.docSet.getDoc(this.state.listId);

    if (currentDoc) {
      const newDoc = Automerge.change(currentDoc, (doc) => {
        const index = doc.items.findIndex((item) => item.id === updatedItem.id);

        const currentItem = doc.items[index];

        Object.entries(currentItem).filter(([key, value]) => updatedItem[key] !== value).forEach(([key, value]) => {
          doc.items[index][key] = updatedItem[key];
        });
      });
      this.docSet.setDoc(this.state.listId, newDoc);
    }
    this.toggleEditMode();
  };

  render() {
    const doc = this.docSet.getDoc(this.state.listId);

    return (
      <div className="app">
        <h1>Lista {this.state.connected ? "📳" : "📴"}</h1>
          {!this.state.listId && <p>⚠️  Please provide a list id.</p>}
        <div>
          <ul>
            <li onClick={this.deleteCompletedItems}>🗑 Erledigte Einträge entfernen</li>
          </ul>
        </div>
        <form className="app-form" onSubmit={this.addItem}>
          <input className="app-input" placeholder="Bitte erledigen" type="text" ref={this.input} />
          <input type="submit" disabled={!this.state.connected} value="✏️" />
        </form>
          {!doc && <div className="loading-container"><LoadingIndicator /><div>Liste wird geladen...</div></div>}
        <ul>
          {doc &&  
              doc.items.map((item) => (
                <ListItem key={item.id} editMode={item.id === this.state.editItem} item={item} onSave={this.editItem} onToggleCompletion={this.toggleItemCompletion} onDelete={this.deleteItem} onToggleEditMode={this.toggleEditMode}/>
              ))}
        </ul>
      </div>
    );
  }
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
